html {
  /* 87,5 % of 16px = 14px */
  font-size: 87.5%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Industry-Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/Industry/Industry-Light.woff") format("woff");
}

@font-face {
  font-family: "Industry-Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/Industry/Industry-Medium.woff") format("woff");
}

@font-face {
  font-family: "Industry-Demi";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assets/fonts/Industry/Industry-Demi.woff") format("woff");
}

@font-face {
  font-family: "Industry-Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/Industry/Industry-Bold.woff") format("woff");
}
