.leaflet-popup-content-wrapper {
  border-radius: 10px;
  padding: 8px;
  padding-top: 0px;
  padding-bottom: 14px;
}
.leaflet-popup-content {
  margin: 0px;
}
.leaflet-container a.leaflet-popup-close-button {
  padding: 8px 6px 0 0;
}
.leaflet-popup-content p {
  margin: 2px 0px;
}
